import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import "react-lazy-load-image-component/src/effects/blur.css"
import Hero from "@components/videoBackgroundHero/videoBackground"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { Tab, Nav } from "react-bootstrap"
import marked from "marked"

// const products = [
//   {
//     name: "Angle Rolls",
//     image: require("@images/angle-rolls.jpg"),
//     link: "/angle-rolls",
//   },
//   {
//     name: "Beam Coping",
//     image: require("@images/beam-drills.jpg"),
//     link: "/beam-coping",
//   },
//   {
//     name: "Folding Machines",
//     image: require("@images/folding-machines.jpg"),
//     link: "/folding-machines",
//   },
//   {
//     name: "Ironworkers",
//     image: require("@images/iron-workers.jpeg"),
//     link: "/ironworkers",
//   },
//   {
//     name: "Press Brakes",
//     image: require("@images/press-brakes.jpg"),
//     link: "/press-brakes",
//   },
//   {
//     name: "Saws",
//     image: require("@images/saws.jpg"),
//     link: "/saws",
//   },
//   {
//     name: "Sheet and Plate Rolls",
//     image: require("@images/sheet-plate-rolls.jpg"),
//     link: "/rolls-sheet-and-plate",
//   },
//   {
//     name: "Tube & Pipe Benders",
//     image: require("@images/tube-pipe-benders.jpg"),
//     link: "/tube-benders",
//   },
// ]

const equipment = [
  {
    image: require("@images/greenleebender2lg.jpg"),
    name: "Greenlee No. 1800 Rigid Conduit Bender",
    link: "/",
  },
  {
    image: require("@images/v18_1lg.jpg"),
    name: "Hyd-Mech V-18 Vertical Tilt Band Saw",
    link: "/",
  },
  {
    image: require("@images/webbroll_2lg.jpg"),
    name: "Webb 3/8” x 8’ Plate Roll",
    link: "/",
  },
  {
    image: require("@images/hwroll_1lg.jpg"),
    name: "Hendley & Whittemore 2-1/2” x 2-1/2” x 1/4” Angle Roll",
    link: "/",
  },
]

const IndexPage = ({ data }) => {
  console.log(data)

  // const serializers = {
  //   types: {
  //     code: props => (
  //       <pre data-language={props.node.language}>
  //         <code>{props.node.code}</code>
  //       </pre>
  //     ),
  //   },
  // }

  const application = data.allSanityApplication.edges
  const productType = data.allSanityProductType.edges

  const params = {
    speed: 500,
    effect: "fade",
    slidesPerView: 1,
    loop: true,
    autoPlay: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: "#equip-next",
      prevEl: "#equip-prev",
    },
  }

  const [defTitle, setDefTitle] = useState(undefined)

  // useEffect(() => {
  //   const testa = () => {
  //     var a = document.querySelector(`a[data-rb-event-key='${defTitle}']`)
  //   }
  //   setTimeout(function() {
  //     testa()
  //   }, 1000)
  // })

  useEffect(() => {
    setDefTitle(localStorage.getItem("mytime"))
  }, [])

  return (
    <Layout>
      <SEO
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
      />
      <div className="homepage-wrapper">
        <div className="homepage-hero">
          <Hero />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 text-center">
                <img
                  className="img-fluid logo"
                  src={require("@images/logo-text-2.png")}
                  width="100%"
                  alt="Fabricating Equipment Sales Co. Logo 2"
                />
                {/*} <h1 className="display-3">
                  Fabricating Equipment Sales Company
                </h1>*/}
                <div className="my-4">
                  <div className="row justify-content-center">
                    <div className="col-md-9">
                      <h1 className="font-weight-normal">
                        Offering Quality Equipment and Service throughout
                        Colorado and Wyoming
                      </h1>
                      <h2 className="font-weight-normal text-white mt-4 font-italic">
                        Since 1973
                      </h2>
                    </div>
                  </div>
                </div>
                <Link
                  to="/products"
                  className="btn btn-primary btn-lg m-2 btn-red"
                >
                  Our Products
                </Link>
                <Link
                  to="/contact"
                  className="btn btn-primary btn-lg m-2 btn-red"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <Link to="/products">
                  <div className="hero-selection-tile">
                    <div className="bg products"></div>
                    <div className="content">
                      <h2>Products</h2>
                      <span className="arrow">
                        <i className="fa fa-long-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              {/*<div className="col-md-3">
              <Link to="/products">
                <div className="hero-selection-tile">
                  <div className="bg application"></div>
                  <div className="content">
                    <h4>Applications</h4>
                    <span className="arrow">
                      <i className="fa fa-long-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </div>*/}
              <div className="col-md-4">
                <Link to="/used-equipment">
                  <div className="hero-selection-tile">
                    <div className="bg equipment"></div>
                    <div className="content">
                      <h2>Used Equipment</h2>
                      <span className="arrow">
                        <i className="fa fa-long-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <a href="tel:303-466-7341">
                  <div className="hero-selection-tile contact">
                    <div className="bg equipment"></div>
                    <div className="content">
                      <h2 className="call-today font-weight-bold">
                        Call
                        <br />
                        Today.
                      </h2>
                      <p className="font-italic">Let us know what you need.</p>
                      <span
                        className="text-white h4 phone"
                        href="tel:303-466-7341"
                      >
                        <i className="fa fa-phone"></i> (303) 466-7341
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs-section border-top">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="bg-white shadow rounded application-tabs-wrapper">
                  <LazyLoadComponent>
                    <Tab.Container
                      defaultActiveKey={
                        defTitle ? defTitle : "general-fabrication"
                      }
                    >
                      <div className="row justify-content-between">
                        <div className="col-md-3 border-right pr-0">
                          <Nav variant="pills" className="flex-column">
                            {application.map(app => (
                              <Nav.Item>
                                <Nav.Link
                                  onClick={e =>
                                    localStorage.setItem(
                                      "mytime",
                                      app.node.slug.current
                                    )
                                  }
                                  eventKey={app.node.slug.current}
                                >
                                  {app.node.title}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        </div>
                        <div className="col-md-9 pl-md-5">
                          <Tab.Content className="py-3">
                            {application.map(app => (
                              <Tab.Pane eventKey={app.node.slug.current}>
                                <span className="text-uppercase font-weight-bold small text-primary">
                                  Application
                                </span>
                                <h3 className="font-weight-bold mb-0 display-5">
                                  {app.node.title}
                                </h3>
                                <div className="row">
                                  <div className="col">
                                    <div className="py-4">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: marked(
                                            app.node.excerpt || ""
                                          ),
                                        }}
                                      />
                                    </div>
                                    <Link
                                      to={`products/${app.node.slug.current}`}
                                      className="btn btn-primary"
                                    >
                                      Browse {app.node.title}
                                    </Link>
                                     <div className="py-4">
                                      <ul className="arrow-list">
                                        {app.node.productType.map(prod => (
                                          <li className="my-1">
                                            <Link className="btn btn-primary py-1 px-2 text-white display-4" to={prod.slug.current}>
                                              {prod.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                 
                                </div>
                              </Tab.Pane>
                            ))}
                          </Tab.Content>
                        </div>
                      </div>
                    </Tab.Container>
                  </LazyLoadComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="featured-products border-bottom border-top bg-light">
          <div className="container">
            <h2 className="text-center">
              Featured <span className="text-red">Products</span>
            </h2>
            <div className="row justify-content-center my-4">
              <div className="col-md-8">
                <p className="text-center m-0">
                  We offer an extensive line of high quality fabricating
                  equipment that provides you with tax benefits, efficiency, and
                  time saving value.
                </p>
              </div>
            </div>
            <div className="row my-5">
              {productType.map(product => (
                <div className="col-md-3 mb-4">
                  <div className="product-tile gen-fab">
                    <Link to={product.node.slug.current}>
                      <div className="tile-bg-wrapper rounded">
                        <div
                          className="tile-bg"
                          style={{
                            backgroundImage: `url(${product?.node?.mainImage?.asset?.fluid?.src})`,
                          }}
                        ></div>
                        <span className="arrow">
                          <i className="fa fa-long-arrow-right"></i>
                        </span>
                      </div>
                      <span className="d-block h5">{product.node.title}</span>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="text-center">
              <Link to="/products" className="btn btn-primary btn-red">
                View All
              </Link>
            </div> */}
            <h2 className="text-center">
              <span className="text-primary">Highest Quality</span> Machines in
              the Market
            </h2>

            <div className="row justify-content-center my-4">
              <div className="col-md-8">
                <p className="text-center mb-5">
                  Established in 1973, Jim Daldos and Virginia Ebert have been
                  representing an extensive line of high quality fabricating
                  equipment. We specialize exclusively in structural steel and
                  precision sheet metal fabricating equipment. We strive to
                  extend our experience to offer our customers the most
                  efficient and cost effective answers to their fabricating
                  challenges.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="equip-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="gallery-swiper-wrapper">
                  <div className="gallery-swiper-nav">
                    <button
                      className="btn btn-primary swiper-nav-btn gallery-prev"
                      id="equip-prev"
                    >
                      <i className="fa fa-long-arrow-left"></i>
                    </button>
                    <button
                      className="btn btn-primary swiper-nav-btn gallery-next"
                      id="equip-next"
                    >
                      <i className="fa fa-long-arrow-right"></i>
                    </button>
                  </div>
                  <LazyLoadComponent>
                    <Swiper {...params}>
                      {equipment.map(equip => (
                        <div
                          className="gallery-img-wrapper"
                          style={{
                            backgroundImage: `url(${equip.image})`,
                          }}
                        ></div>
                      ))}
                    </Swiper>
                  </LazyLoadComponent>
                </div>
              </div>
              <div className="col-md-6 pl-md-5">
                <h2 className="mb-4 text-white">
                  Used <span>Equipment</span>
                </h2>
                <div className="my-4">
                  <p className="lead font-weight-bold text-white-50">
                    Before you hire another person for your shop, consider the
                    benefits of adding equipment to make your existing crew more
                    efficient.
                  </p>
                  <p className="text-white">
                    Our used equipment is available for shipment anywhere. Just
                    give us a call for shipping details and rates. Feel free to
                    make an offer on any of our products, or give us a call at
                    303-466-7341 or 303-550-0734 for further information.
                  </p>
                </div>
                <Link to="/used-equipment" className="btn btn-primary mt-4">
                  Browse Our Machinery
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="articles-section border-top border-bottom bg-light">
        <div className="container">
          <h2 className="text-center"> Information Center</h2>
          <div className="row my-5">
            {blog.map(post => (
              <div className="col-md-4 mb-4 text-left">
                <div className="blog-item">
                  <Link to={post.link}>
                    <div className="blog-img-tile">
                      <div className="tile-bg-wrapper">
                        <span className="doc-icon">
                          <i className="fa fa-file-text"></i>
                        </span>
                        <div
                          className="tile-bg rounded"
                          style={{ backgroundImage: `url(${post.image})` }}
                        ></div>
                      </div>
                    </div>
                  </Link>
                  <div className="my-3">
                    <span className="text-uppercase font-weight-bold small d-block">
                      {post.date}
                    </span>
                    <Link to={post.link} className="h4">
                      {post.title}
                    </Link>
                    <p className="small mt-2">{post.excerpt} </p>
                  </div>
                  <Link
                    to={post.link}
                    className="btn btn-secondary btn-secondary-red btn-sm"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center">
            <Link to="/blog" className="btn btn-primary">
              More from the Blog
            </Link>
          </div>
        </div>
      </div>*/}
      </div>
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    siteSettings: sanitySiteSettings {
      title
      description
    }
    aboutPage: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
    allSanityApplication(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          slug {
            current
          }
          excerpt
          id
          productType {
            id
            title
            slug {
              current
            }
          }
        }
      }
    }
    allSanityProductType(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          slug {
            current
          }
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          application {
            id
          }
        }
      }
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
