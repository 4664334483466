import React from "react"

const VideoBG = () => (
	<div class="video-background">
		<iframe
			src="https://www.youtube.com/embed/a1GHBzzca7I?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&playlist=a1GHBzzca7I"
			frameborder="0"
			allowfullscreen
			title="Hero BG"
		></iframe>
	</div>
)

export default VideoBG
